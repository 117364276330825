import React, { useReducer, useState, useMemo } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useFreeFetch } from '../fetch';
import { IntlProvider } from 'react-intl';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const LanguageContext = React.createContext();

export default function LanguageProvider(props) {
    const [ langCode, setLangCode ] = useState(() => localStorage.getItem('locale') || 'en');
    
    const [ languageList ] = useFreeFetch('/language');
   
    const activeLanguage = useMemo(() => (languageList && languageList.data.find(a => a.language_code === langCode)) || languageList?.data?.[0], [languageList, langCode]);
    
    const data = useMemo(() => ({activeLanguage, languageList: languageList && languageList.data, langCode, setLangCode}), [activeLanguage, languageList, langCode, setLangCode]);

    const [ messages ] = useFreeFetch(activeLanguage && activeLanguage.precompiled_json.access_url);

    if (!languageList || !messages)
        return null;

    return <LanguageContext.Provider value={data}>
        <IntlProvider messages={messages} locale={activeLanguage.code} defaultLocale="en" {...props} />
    </LanguageContext.Provider>;
}

export function LanguageSwitcherBasic({ className }) {
    const { languageList, activeLanguage, langCode, setLangCode } = useContext(LanguageContext);
    const [ isOpen, toggleMenu ] = useReducer((a) => !a, false);

    const changeLang = (lang, e) => {
        e.preventDefault();
        setLangCode(lang);
        localStorage.setItem('locale', lang);
    };

    return <Dropdown isOpen={isOpen} toggle={toggleMenu} id="languagemenu" className={className}>
        <DropdownToggle tag="button" className="btn header-item waves-effect">
            { activeLanguage.icon && <img className="mr-2" src={activeLanguage.icon.access_url} alt="Header Language" height="16"/> } { activeLanguage.name } <span className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu right>
            { languageList
                .map(a => <DropdownItem key={a.language_code} tag="a" href="#" className="notify-item" onClick={changeLang.bind(this, a.language_code)}>
                     { a.icon && <img className="mr-2" src={a.icon.access_url} alt={a.name} height="8"/> } <span className="align-middle">{ a.name }</span>
                </DropdownItem>) }
        </DropdownMenu>
    </Dropdown>;
}

